





































































































































































// CORE
import { Component, Mixins } from 'vue-property-decorator'

// COMPONENTS
import { ValidationObserver, ValidationProvider } from 'vee-validate'

import DefaultLayout from '@/components/layouts/DefaultLayout.vue'
import RequestFormPartWrapper from '@/components/RequestFormPartWrapper.vue'
import CarFormPart from '@/components/forms/parts/CarFormPart.vue'
import CharacterCard from '@/components/cards/CharacterCard.vue'
import ControlsRequestFormPart from '@/components/forms/parts/ControlsRequestFormPart.vue'
import PlaceAndDateFormPart from '@/components/forms/parts/PlaceAndDateFormPart.vue'
import CarOrderView from '@/components/orderViews/CarOrderView.vue'

// INTERFACES
import { RequestsAttorneyPdfDownloadGetParams } from '@/store/types'

// STORE
import OrderModule from '@/store/modules/order'
import FleetOrderModule from '@/store/modules/fleet/order'
import DriverOrderModule from '@/store/modules/driver/order'

// MIXINS
import SystemMixin from '@/mixins/SystemMixin'
import NotifyMixin from '@/mixins/NotifyMixin'

@Component({
  components: {
    DefaultLayout,
    ValidationProvider,
    ValidationObserver,
    CharacterCard,
    CarFormPart,
    ControlsRequestFormPart,
    PlaceAndDateFormPart,
    RequestFormPartWrapper,
    CarOrderView,
  },
})
export default class CarPowerAttorneyForDrivingCreate extends Mixins(SystemMixin, NotifyMixin) {
  private rules = {
    count: (value: string) => value.length <= 500,
  }

  private dates = {
    dateReturn: '',
    dateTransfer: '',
  }

  private passportDetails = {
    surname: '',
    name: '',
    patronymic: '',
    num: '',
    date: '',
    issued: '',
    subdivision: '',
  }

  // FORM
  private form: RequestsAttorneyPdfDownloadGetParams = {
    carId: 0, // ID ТС
    issued1: '', // Выдан1 (Паспорт)
    issued2: '', // Выдан2 (Паспорт)
    issued3: '', // Подразделение (Паспорт)
    passport: '', // Паспорт гражданина РФ, серия
    fio: '', // ФИО (Паспорт)
    carBrand: '', // Марка ТС
    carModel: '', // Модель ТС
    carYear: '', // Год изготовления ТС
    carVin: '', // VIN
    carNumber: '', // Гос. Регистрационный знак
    carSts: '', // СТС
    order: '', // Номер доверенности
    date: '', // Дата доверенности
  }

  private isShowEditCar = true
  private isShowCarDriving = false
  private isSpecificCar = false

  private get operationCityList (): string[] {
    if (this.role === 'fleet' || this.role === 'mechanic') {
      return FleetOrderModule.operationCitiesDictionary
    } else {
      this.form.carId = +this.entity
      return DriverOrderModule.operationCitiesDictionary
    }
  }

  private get currentMileage () {
    return OrderModule.carSearchResponse.currentMileage
  }

  private created () {
    if (this.carId) {
      this.form.carId = +this.carId
      this.isSpecificCar = true
    } else {
      this.form.carId = 0
      this.isSpecificCar = false
    }

    if (this.role === 'fleet' || this.role === 'mechanic') {
      const payload = {
        entity: +this.entity,
        params: {
          hasRepair: true,
        },
      }
      FleetOrderModule.getOrderDictionaries(payload)
    } else {
      this.form.carId = +this.entity
      DriverOrderModule.getOrderDictionaries(+this.entity)
    }
  }

  private handleSetCarInfo (payload: any) {
    this.form.carBrand = payload.brand
    this.form.carModel = payload.model
    this.form.carYear = payload.productionYear
    this.form.carVin = payload.vin
    this.form.carNumber = payload.stateNumber
    this.form.carSts = `${payload.stsSeria} ${payload.stsNumber}`
    this.form.order = payload.orderNumber
    this.dates.dateReturn = payload.dateOfActOfReturn
    this.dates.dateTransfer = payload.dateOfActOfTransfer
  }

  private handleSubmit () {
    const form: any = this.$refs.form

    form.validate()
      .then(async (result: boolean) => {
        if (result) {
          this.form.issued2 = `${this.passportDetails.issued}`
          this.form.issued3 = `${this.passportDetails.subdivision}`
          this.form.passport = `${this.passportDetails.num}`
          this.form.fio = `${this.passportDetails.surname} ${this.passportDetails.name} ${this.passportDetails.patronymic}`

          const body: any = {
            ...this.form,
          }

          if (this.currentMileage) {
            body.currentMileage = this.currentMileage
          }

          DriverOrderModule.getAttorneyPdfDownload(body)
            .then((r) => {
              this.$openModal(
                'PDFDownloadModal',
                'small-no-absolute',
                {
                  exportURL: r,
                  checkedItems: [],
                  errorItems: [],
                  type: 'proxy',
                },
              )
              this.resetForm()
              form.reset()
              this.$router.push({ name: 'orders' })
            })
            .catch((err) => {
              this.notifyError(err)
            })

          // if (this.role === 'fleet') {
          //   body.author.type = 'manager'
          //
          //   FleetOrderModule.sendCarBuyback({ entity: +this.entity, body })
          //     .then((r) => {
          //       this.$openModal('OrderSendSuccessModal', 'big', {
          //         route: {
          //           name: `view-order.${r.type}`,
          //           params: { orderId: r.id },
          //         },
          //       })
          //       this.$router.push({ name: 'orders' })
          //     })
          //     .catch(() => {
          //       this.notifyError('Заявка не отправлена. Необходимо заполнить все обязательные поля.')
          //     })
          // }
          // else {
          //   body.carId = +this.entity
          //   body.author.type = 'driver'
          //
          //   DriverOrderModule.sendCarBuyback({ entity: +this.entity, body })
          //     .then((r) => {
          //       this.$openModal('OrderSendSuccessModal', 'big', {
          //         route: {
          //           name: `view-order.${r.type}`,
          //           params: { orderId: r.id },
          //         },
          //       })
          //       this.$router.push({ name: 'orders' })
          //     })
          //     .catch(() => {
          //       this.notifyError('Заявка не отправлена. Необходимо заполнить все обязательные поля.')
          //     })
          // }
        }
      })
  }
  private resetForm () {
    this.form = {
      carId: 0,
      issued1: '',
      issued2: '',
      issued3: '',
      passport: '',
      fio: '',
      carBrand: '',
      carModel: '',
      carYear: '',
      carVin: '',
      carNumber: '',
      carSts: '',
      order: '',
      date: '',
    }
    this.passportDetails = {
      surname: '',
      name: '',
      patronymic: '',
      num: '',
      date: '',
      issued: '',
      subdivision: '',
    }
    this.dates = {
      dateReturn: '',
      dateTransfer: '',
    }
  }
}
