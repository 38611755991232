













































// CORE
import { Component, Prop, Vue } from 'vue-property-decorator'

@Component
export default class PlaceAndDateFormPart extends Vue {
  @Prop({ default: () => ({}) })
  readonly data!: { city: string, date: string }

  @Prop({ default: false })
  readonly isReadonly!: boolean

  @Prop({ default: false })
  readonly additionalInput!: boolean

  private get innerForm (): any {
    return this.data
  }

  private set innerForm (value: any) {
    this.$emit('update:data', {
      ...this.innerForm,
      ...value,
    })
  }
}
