var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('DefaultLayout',{staticClass:"order",attrs:{"breadcrumbs":[{ name: 'Заявки', value: 'orders' }],"isHideFiltersPanel":true,"heading":"Заявка на  доверенность управления ТС"}},[_c('ValidationObserver',{ref:"form",staticClass:"maintenance-order-create order-form",attrs:{"tag":"form"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('div',{staticClass:"order-form__container sp-xl"},[_c('RequestFormPartWrapper',{attrs:{"name":"Автомобиль","number":"01"}},[(!_vm.isSpecificCar)?_c('CarFormPart',{attrs:{"id":_vm.form.carId,"isRepair":true,"extra":true},on:{"update:id":function($event){return _vm.$set(_vm.form, "carId", $event)},"set-obj":_vm.handleSetCarInfo}}):_vm._e()],1),_c('RequestFormPartWrapper',{attrs:{"name":"Дата","number":"02"}},[_c('div',{staticClass:"order-form__transfer-and-return--grid"},[_c('ValidationProvider',{attrs:{"tag":"div","rules":"required","name":"dateStart"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('DatePicker',{attrs:{"range":false,"label":"Дата акта передачи*","required":true,"error":errors[0],"small":true,"isNotPrevDate":true},model:{value:(_vm.dates.dateTransfer),callback:function ($$v) {_vm.$set(_vm.dates, "dateTransfer", $$v)},expression:"dates.dateTransfer"}},[_c('Paragraph',{staticClass:"display-contents",attrs:{"tag":"span","size":"label","theme":"color-inherit"}},[_vm._v("Дата акта передачи"),_c('b',[_vm._v("*")])])],1)]}}],null,true)}),_c('ValidationProvider',{attrs:{"tag":"div","rules":"required","name":"dateEnd"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('DatePicker',{attrs:{"range":false,"label":"Плановая дата возврата*","required":true,"error":errors[0],"small":true,"isNotPrevDate":true},model:{value:(_vm.dates.dateReturn),callback:function ($$v) {_vm.$set(_vm.dates, "dateReturn", $$v)},expression:"dates.dateReturn"}},[_c('Paragraph',{staticClass:"display-contents",attrs:{"tag":"span","size":"label","theme":"color-inherit"}},[_vm._v("Плановая дата возврата"),_c('b',[_vm._v("*")])])],1)]}}],null,true)})],1)]),_c('RequestFormPartWrapper',{attrs:{"name":"Паспортные данные","number":"03"}},[_c('div',{staticClass:"car-form-part"},[_c('ValidationProvider',{attrs:{"tag":"div","rules":"required","name":"passportSurname"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var validation = ref.validation;
return [_c('TextInput',{staticClass:"car-form-part__item",attrs:{"label":"Фамилия*","small":true,"error":errors[0],"required":true},model:{value:(_vm.passportDetails.surname),callback:function ($$v) {_vm.$set(_vm.passportDetails, "surname", $$v)},expression:"passportDetails.surname"}},[_c('Paragraph',{staticClass:"display-contents",attrs:{"tag":"span","size":"label","theme":"color-inherit"}},[_vm._v("Фамилия"),_c('b',[_vm._v("*")])])],1)]}}],null,true)}),_c('ValidationProvider',{attrs:{"tag":"div","rules":"required","name":"passportName"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var validation = ref.validation;
return [_c('TextInput',{staticClass:"car-form-part__item",attrs:{"label":"Имя*","small":true,"error":errors[0],"required":true},model:{value:(_vm.passportDetails.name),callback:function ($$v) {_vm.$set(_vm.passportDetails, "name", $$v)},expression:"passportDetails.name"}},[_c('Paragraph',{staticClass:"display-contents",attrs:{"tag":"span","size":"label","theme":"color-inherit"}},[_vm._v("Имя"),_c('b',[_vm._v("*")])])],1)]}}],null,true)}),_c('ValidationProvider',{attrs:{"tag":"div","rules":"required","name":"passportPatronymic"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var validation = ref.validation;
return [_c('TextInput',{staticClass:"car-form-part__item",attrs:{"label":"Отчество*","small":true,"error":errors[0],"required":true},model:{value:(_vm.passportDetails.patronymic),callback:function ($$v) {_vm.$set(_vm.passportDetails, "patronymic", $$v)},expression:"passportDetails.patronymic"}},[_c('Paragraph',{staticClass:"display-contents",attrs:{"tag":"span","size":"label","theme":"color-inherit"}},[_vm._v("Отчество"),_c('b',[_vm._v("*")])])],1)]}}],null,true)}),_c('ValidationProvider',{attrs:{"tag":"div","rules":"required","name":"passport"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var validation = ref.validation;
return [_c('TextInput',{staticClass:"car-form-part__item",attrs:{"label":"Паспорт*","mask":"## ## ######","small":true,"error":errors[0],"required":true},model:{value:(_vm.passportDetails.num),callback:function ($$v) {_vm.$set(_vm.passportDetails, "num", $$v)},expression:"passportDetails.num"}},[_c('Paragraph',{staticClass:"display-contents",attrs:{"tag":"span","size":"label","theme":"color-inherit"}},[_vm._v("Паспорт"),_c('b',[_vm._v("*")])])],1)]}}],null,true)}),_c('ValidationProvider',{attrs:{"tag":"div","rules":"required","name":"passportDate"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('DatePicker',{attrs:{"range":false,"label":"Дата выдачи*","required":true,"error":errors[0],"small":true,"isNotPrevDate":false},model:{value:(_vm.form.issued1),callback:function ($$v) {_vm.$set(_vm.form, "issued1", $$v)},expression:"form.issued1"}},[_c('Paragraph',{staticClass:"display-contents",attrs:{"tag":"span","size":"label","theme":"color-inherit"}},[_vm._v("Дата выдачи"),_c('b',[_vm._v("*")])])],1)]}}],null,true)}),_c('ValidationProvider',{attrs:{"tag":"div","rules":"required","name":"passportIssued"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var validation = ref.validation;
return [_c('TextInput',{staticClass:"car-form-part__item",attrs:{"label":"Кем выдан*","small":true,"error":errors[0],"required":true},model:{value:(_vm.passportDetails.issued),callback:function ($$v) {_vm.$set(_vm.passportDetails, "issued", $$v)},expression:"passportDetails.issued"}},[_c('Paragraph',{staticClass:"display-contents",attrs:{"tag":"span","size":"label","theme":"color-inherit"}},[_vm._v("Кем выдан"),_c('b',[_vm._v("*")])])],1)]}}],null,true)}),_c('ValidationProvider',{attrs:{"tag":"div","rules":"required","name":"passportSubdivision"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var validation = ref.validation;
return [_c('TextInput',{staticClass:"car-form-part__item",attrs:{"label":"№ Подразделения*","mask":"###-###","small":true,"error":errors[0],"required":true},model:{value:(_vm.passportDetails.subdivision),callback:function ($$v) {_vm.$set(_vm.passportDetails, "subdivision", $$v)},expression:"passportDetails.subdivision"}},[_c('Paragraph',{staticClass:"display-contents",attrs:{"tag":"span","size":"label","theme":"color-inherit"}},[_vm._v("№ Подразделения"),_c('b',[_vm._v("*")])])],1)]}}],null,true)})],1)])],1),_c('div',{staticClass:"order-form__container"},[_c('ControlsRequestFormPart',{attrs:{"send-text":'Сформировать'},on:{"submit":_vm.handleSubmit}})],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }